

$(document).ready(function(){

	$('.song').each(function(){
		index = $(this).index();
		$(this).data("number", index);
		$(this).data("set", false);
	});


	$('.song').click(function(){

		index = $(this).data("number");

		// set audio if first time clicked
		if(!$(this).data('set')){

			var zis = $(this);

			// get song we clicked on
			song = songs[index];

			// create new audio for it
			var newAudio = 'audio' + index;
			zis.data('audio', newAudio);
			newAudio = new Audio("/uploads/" + song);
			songs[index] = newAudio;
			zis.data('set', true);

			songs[index].addEventListener("ended", function(){ gotonextsong(index, $(this)); });
		} 

		// Pause track when click on track playing
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$(this).addClass('pause');
			songs[index].pause();

		// Play track when click on track paused
		} else if($(this).hasClass('pause')){
			$('.aboutContentContainer').removeClass('active');
			$('.lyrics').removeClass('hide');
			$(this).removeClass('pause');
			$(this).addClass('active');
			songs[index].play();


		// Play track on click
		} else {
			$('.aboutContentContainer').removeClass('active');
			$('.lyrics').removeClass('hide');
			$('.song').removeClass('active');
			$('.song').removeClass('pause');

			$(this).addClass('active');

			$('.song').each(function(){
				if($(this).data('set')){
					indexToStop = $(this).data("number");

					if (songs[indexToStop].duration > 0) {
						songs[indexToStop].pause();
						songs[indexToStop].currentTime = 0;
					}

					$(this).find('.bar').css('width', 0);
				}
			});

			lyricContainer = $('.lyricContainer').eq(index);

			$('.lyricContainer').removeClass('active');
			randomizeLyric(lyricContainer);
			lyricContainer.addClass('active');

			songs[index].play();
		}

		bar = $(this).find('.bar');

		songs[index].addEventListener("timeupdate", function(){ seektimeupdate(songs[index], bar); });

	
	});

	function gotonextsong(index, songObj){
		nextSong = $('.song').eq(index + 1);
		if(!nextSong.length){

			songs[index].pause();
			songs[index].currentTime = 0;
			$('.song').removeClass('active');
			songObj.find('.bar').css('width', 0);
			$('.lyricContainer').removeClass('active');


		} else {
			nextSong.trigger( "click" );
		}
		
	};
	

	function seektimeupdate(audio, bar){
		var nt = audio.currentTime * (100 / audio.duration);
		var roundedString = nt.toFixed(2);
		rounded = Number(roundedString);

		bar.css('width', rounded + '%');
	

		// TIMER EN SECONDE
		// var curmins = Math.floor(audio.currentTime / 60);
	 //    var cursecs = Math.floor(audio.currentTime - curmins * 60);
	 //    var durmins = Math.floor(audio.duration / 60);
	 //    var dursecs = Math.floor(audio.duration - durmins * 60);
		// if(cursecs < 10){ cursecs = "0"+cursecs; }
	 //    if(dursecs < 10){ dursecs = "0"+dursecs; }
	 //    if(curmins < 10){ curmins = "0"+curmins; }
	 //    if(durmins < 10){ durmins = "0"+durmins; }
		// console.log(curmins+":"+cursecs);
	 //    console.log(durmins+":"+dursecs);
	}

	function randomizeLyric(lyricContainer){
		var colorPalette = ['#a1665b', '#6b6064', '#2d2d2e', '#634332', '#987f88', '#050404'];

		var randomColor = colorPalette[Math.floor(Math.random()*colorPalette.length)];

		var l = lyricContainer.find('.lyric').length;
		var h = $('.topSite').outerHeight();
		var w = $('.topSite').outerWidth();
		var i = 0;


		lyricContainer.find('.lyric').each(function(){

			thisW = $(this).outerWidth();
			thisH = $(this).outerHeight();

			// APPLY RANDOM COLOR TO EACH BLOCK
			var randomColor = colorPalette[Math.floor(Math.random()*colorPalette.length)];
			$(this).css('background', randomColor)

			//APPLY RANDOM PLACEMENT TO EACH BLOCK
			ratioH = h / l;
			topPos = ratioH * i + Math.floor(Math.random() * 20);
			ww = w - (thisW);
			leftPos = Math.floor(Math.random() * ww);

			//LIFT UP THE BLOCK IF TO CLOSE TO BOTTOM
			if(topPos + thisH > h){
					topPos = h - (thisH + 20); 
			}

			$(this).css({
				'top': topPos + 'px',
				'left': leftPos + 'px'
			})

			i++;
		});
	}

	// NAV AND MENU


	
	$('.aboutBtn').click(function(){



		var colorPalette = ['#a1665b', '#6b6064', '#2d2d2e', '#634332', '#987f88', '#050404'];

		$('.aboutContentContainer').toggleClass('active');

		if($('.aboutContentContainer').hasClass('active')){
			$('.lyrics').addClass('hide');

			var randomColor = colorPalette[Math.floor(Math.random()*colorPalette.length)];

			var l = $('.aboutContent').length;
			var h = $('.topSite').outerHeight();
			var w = $('.topSite').outerWidth();
			var i = 0;


			$('.aboutContent').each(function(){

				thisW = $(this).outerWidth();
				thisH = $(this).outerHeight();

				// APPLY RANDOM COLOR TO EACH BLOCK
				var randomColor = colorPalette[Math.floor(Math.random()*colorPalette.length)];
				$(this).css('background', randomColor)

				//APPLY RANDOM PLACEMENT TO EACH BLOCK
				ratioH = h / l;
				topPos = ratioH * i + Math.floor(Math.random() * 20);
				ww = w - (thisW);
				leftPos = Math.floor(Math.random() * ww);

				$(this).css({
					'top': topPos + 'px',
					'left': leftPos + 'px'
				})

				i++;
			});
		} else {
			$('.lyrics').removeClass('hide');
		}
	});

	var $draggable = $('.draggable').draggabilly({
		containment: '.topSite'
	})

	// $draggable.on( 'dragStart', function( event, pointer ) {
	// 	$('.aboutContent').css('z-index', 1);
	// 	$(this).css('z-index', 2);
	// });

});





